import React from 'react'
import {Snackbar as SnackBarMui} from '@mui/material'
import {SnackBarModel} from '../types/snackBarModel'
import MuiAlert, {AlertProps} from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

type SnackBarProps = SnackBarModel

const SnackBar: React.FC<SnackBarProps> = (
    {
        open,
        onSnackClosed,
        message,
        color
    }
) => {
    return (
        <SnackBarMui
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={open}
            autoHideDuration={3000}
            onClose={onSnackClosed}
        >
            {
                message.length > 0 ? (
                    <Alert onClose={onSnackClosed} severity={color} sx={{width: '100%'}}>
                        {message}
                    </Alert>
                ) : (
                    <div />
                )
            }
        </SnackBarMui>
    )
}

export default SnackBar
