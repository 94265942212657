import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {Grid, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {responseEnum} from './enums/responseEnum'
import SnackBar from './components/SnackBar'
import {INIT_SNACKBAR, SnackBarModel} from './types/snackBarModel'
import {localStorageEnum} from '../../containers/enums/localStorageEnum'

interface AcceptingProps {
    step: number,
    setStep: Dispatch<SetStateAction<number>>
}

type InputProps = AcceptingProps

const Input: React.FC<InputProps> = (
    {
        step,
        setStep
    }
) => {
    const inputName = 'textInput'
    const [inputValue, setInputValue] = React.useState('')
    const [snackBarData, setSnackBarData] = React.useState<SnackBarModel>(INIT_SNACKBAR)
    const [response, setResponse] = React.useState<number[] | string | number>(responseEnum.step1)

    const managePlaceholder = () => {
        if (step <= 4) {
            return 'Indiquez la date du tableau'
        }
        if (step === 5) {
            return 'Qui suis-je ?'
        }

        return 'Quel âge avais-je ?'
    }

    const normalizeText = (str: string) => str.normalize("NFD").replace(/\p{Diacritic}/gu, "")

    const onSubmit = () => {
        // Dans le cas ou nous devons devines les dates des 5 tableaux, alors response est un tableau et on enlève la réponse au tableau
        if (Array.isArray(response) && response.includes(Number(inputValue))) {
            setResponse((prevState) => (prevState as number[]).filter((responseFiltered) => responseFiltered !== Number(inputValue)))
            setStep((prevState) => prevState + 1)

            setSnackBarData({
                open: true,
                onSnackClosed: () => setSnackBarData(INIT_SNACKBAR),
                message: `Bravo ! C'était bien ${inputValue} ! Continuez comme ça !`,
                color: 'success'
            })
        }
        // Si on est à l'étape 2 (les 5 tableaux ont été trouvés), alors on doit trouver le nom de l'auteur
        else if (typeof response === 'string' && normalizeText(response.toLowerCase()) === normalizeText(inputValue.toLowerCase())) {
            setStep((prevState) => prevState + 1)

            setSnackBarData({
                open: true,
                onSnackClosed: () => setSnackBarData(INIT_SNACKBAR),
                message: `Bravo ! C'était bien ${response} ! Encore un dernier effort !`,
                color: 'success'
            })
        }
        // Si on est à l'étape 3 (5 tableaux + nom auteur, alors on doit trouver le dernier tableau
        else if (typeof response === 'number' && response === Number(inputValue)) {
            setStep((prevState) => prevState + 1)

            setSnackBarData({
                open: true,
                onSnackClosed: () => setSnackBarData(INIT_SNACKBAR),
                message: 'Félicitation ! C\'était le dernier indice à trouver !',
                color: 'success'
            })
        } else {
            setSnackBarData({
                open: true,
                onSnackClosed: () => setSnackBarData(INIT_SNACKBAR),
                message: 'Mauvaise réponse ! Essayez encore',
                color: 'error'
            })
        }

        setInputValue('')
    }

    useEffect(
        () => {
            // Si on a demandé à réinitialisé les steps, on réinitialise les réponses
            if (step === 0) {
                setResponse(responseEnum.step1)
            }

            // Lorsque la valeur de l'étape change, ce qui arrive lors d'une bonne réponse, on affiche un message de succès
            if (step > 0 && step !== Number(localStorage.getItem(localStorageEnum.step) ?? 0)) {
                setSnackBarData({
                    open: true,
                    onSnackClosed: () => setSnackBarData(INIT_SNACKBAR),
                    message: 'Bien joué ! C\'est une bonne réponse !',
                    color: 'success'
                })
            }

            // Si les 5 tableaux ont été trouvés, on peut passer à l'étape suivante (qui est de trouver le peintre)
            if (step === 5) {
                setResponse(responseEnum.step2)
            }

            // Si le peintre a été trouvé, on peut passer à la dernière étape
            if (step === 6) {
                setResponse(responseEnum.step3)
            }

            localStorage.setItem(localStorageEnum.step, step.toString())
        }, [step]
    )

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    background: 'white',
                    padding: '20px',

                    '& > div:first-child': {
                        flexGrow: 2
                    }
                }}
            >
                <TextField
                    value={inputValue}
                    name={inputName}
                    type="text"
                    placeholder={managePlaceholder()}
                    onChange={(e) => setInputValue(e.target.value)}
                />

                <SearchIcon
                    sx={{
                        height: '50px',
                        width: '50px',
                        ml: 1,
                        background: '#F4D3BF',
                        color: 'white',
                        borderRadius: 25,
                        boxSizing: 'border-box',
                        p: 1,
                        cursor: 'pointer'
                    }}
                    onClick={onSubmit}
                />
            </Grid>

            <SnackBar
                open={snackBarData.open}
                onSnackClosed={snackBarData.onSnackClosed}
                message={snackBarData.message}
                color={snackBarData.color}
            />
        </>
    )
}

export default Input
