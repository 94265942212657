export const stepsTexts = {
	step1: {
		aspectRatio: 116 / 161,
		text: '<h1>1800 - 1899</h1>' +
			'Bravo ! Vous avez trouvé la première ombre tourmentée. Malgré son aspect terrifiant, vous échangez avec elle et celle-ci vous révèle un indice : <br />' +
			'"le peintre que vous recherchez a vécu au XIXe siècle..."' +
			'<img src="/tips/tip1_footer.png" alt="Footer 1er indice" style="vertical-align: bottom" />'
	},
	step2: {
		aspectRatio: 327 / 187,
		text: 'A votre vue, l\'ombre vous tourne le dos, comme pour cacher son aspect terrible. Elle rugit ce précieux indice : <br />' +
			'"A l\'instar de beaucoup d\'artistes, le peintre que vous recherchez s\'est rendu en Italie, de 1817 à 1822"' +
			'<img src="/tips/tip2_footer.png" alt="Footer 2er indice" style="vertical-align: middle" />'
	},
	step3: {
		aspectRatio: 269 / 254,
		text: 'Un pas de plus pour identifier le peintre tourmenté. Dans un murmure, l\'ombre vous révèle qu\'il était professeur de dessin à Paris.<br />' +
			'Y voyez-vous plus clair ?'
	},
	step4: {
		aspectRatio: 282 / 210,
		text: 'Vous touchez bientôt au but ! L\'ombre tourmentée vous souffle d\'une voix d\'outretombe :<br />' +
			'"Ouvrez l\'oeil... le peintre que vous recherchez est entouré de sa famille"<br />' +
			'<img src="/tips/tip4_footer.png" alt="Footer 4er indice" style="vertical-align: bottom" />'
	},
	step5: {
		aspectRatio: 203 / 129,
		text: 'La route pour mener votre enquête est longue, il vous semble que le mystère qui entoure l\'identité du peintre s\'éclaircit... l\'ombre vous apporte un indice déterminant : il porte un foulard rouge !'
	},
	step6: {
		aspectRatio: null,
		text: 'Vous avez retrouvé l\'identité de l\'artiste. Mais en arrivant devant son autoportrait, un mot glisse du cadre. Il s\'agit d\'un message de l\'ombre de Cogniet :<br/>' +
			'<span>Qsr eqi ri xvsyzi tew pi vitsw. Virhid-zsyw hizerx qsr hivrmiv zmweki</span><br />' +
			'<img src="/tips/tip6_footer.png" alt="Tableau de conversion" style="width: 80%; max-width: 350px; aspect-ratio: 350/402"/><br />' +
			'Décryptez le message ; pour découvrir la clé de lecture, regardez les indices précédents. Vous y trouverez tout ce dont vous avez besoin pour y voir plus clair.'
	},
	step7: {
		aspectRatio: null,
		text: 'Bravo mes amis, merci de m\'avoir retrouvé. Ma vie a été longue et bien remplie. Mais avec ces ombres tourmentées, mon âme ne trouve pas la paix non plus. Ma mémoire est encore très vive aujourd\'hui, au musée. Vous trouverez mon autel au sous-sol du musée.<br />' +
			'La peinture et le dessin sont mon guide! Dessinez moi ce qui vous apporte le plus de joie... cette charge positive appaisera mon esprit.'
	}
}
