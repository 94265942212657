import React, {useState} from 'react'
import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material'
import {localStorageEnum} from '../enums/localStorageEnum'
import CloseIcon from '@mui/icons-material/Close'

const InformativeModal = () => {
    const [open, setOpen] = useState(localStorage.getItem(localStorageEnum.modalDisplayed) ? localStorage.getItem(localStorageEnum.modalDisplayed) === 'true' : true)
    const onClose = () => {
        setOpen(false)
        localStorage.setItem(localStorageEnum.modalDisplayed, 'false')
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Information d'usage

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                Samaïn approche... à cette époque de l'année, les frontières entre visible et invisible s'amenuisent et le passage vers le Sidh plus simple. Ma soeur et moi sommes assaillies par les demandes d'outre-tombe pour les apaiser. L'une d'entre elle a attiré notre attention. L'esprit d'un peintre dont nous ignorons l'identité ne trouve pas le repos. Nous ne pouvons malheureusement pas nous occuper de cette affaire. De son vivant, il avait des visions épouvantables dont il a laissé des traces. Peut-être que ces réalisations vous donnerons des indices. Retrouvez l'identité de cet artiste et délivrez-le de ses tourments.
            </DialogContent>
        </Dialog>
    )
}

export default InformativeModal
