import React from 'react'
import {Box, Grid} from '@mui/material'
import FadeIn from 'react-fade-in'
import {stepsTexts} from './enums/texts'

interface AcceptingProps {
    step: number
}

type TipsProps = AcceptingProps

const Tips: React.FC<TipsProps> = (
    {
        step
    }
) => {
    return (
        <Grid
            container
            item
            xs={12}
            sx={{
                color: 'white'
            }}
            justifyContent="center"
        >
            {
                Object.values(stepsTexts).map(
                    (stepTexts, index) => {
                        // Si l'index est inférieur à l'étape pour laquelle on est, on masque le contenu
                        if (index + 1 <= step) {
                            return (
                                <FadeIn
                                    delay={50}
                                    transitionDuration={1000}
                                    key={stepTexts.text}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{
                                            mt: 2
                                        }}
                                    >
                                        {
                                            // Si on est à l'étape 5, on ajoute un texte spécifique
                                            index + 1 === 5 && (
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        fontFamily: 'Aleo-Light',
                                                        fontSize: 30,
                                                        position: 'absolute',
                                                        left: 30
                                                    }}
                                                >
                                                    E.I.
                                                </Box>
                                            )
                                        }
                                        {
                                            // On masque l'image s'il n'y a pas d'indication sur le ratio
                                            stepTexts.aspectRatio && (
                                                <Grid container item xs={12} justifyContent="center">
                                                    <img src={`/tips/tip${index + 1}.png`} alt={`Indice ${index + 1}`} style={{ aspectRatio: stepTexts.aspectRatio }}/>
                                                </Grid>
                                            )
                                        }

                                        <Grid
                                            item
                                            xs={12}
                                            dangerouslySetInnerHTML={{__html: stepTexts.text}}
                                            sx={{
                                                '& span': {
                                                    fontFamily: 'times new roman',
                                                    fontSize: 20,
                                                    display: 'block',
                                                    marginTop: 5,
                                                    marginBottom: 1
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <br />
                                    <br />
                                </FadeIn>
                            )
                        }

                        return <React.Fragment key={stepTexts.text} />
                    }
                )
            }
        </Grid>
    )
}

export default Tips
