import React, {useEffect} from 'react'
import {Button, Grid} from '@mui/material'
import Input from '../modules/input/Input'
import '../font/AlexBrush.css'
import '../font/Aleo.css'
import './App.css'
import Tips from '../modules/tips/Tips'
import Pictures from '../modules/pictures/Pictures'
import {localStorageEnum} from './enums/localStorageEnum'
import InformativeModal from './components/InformativeModal'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function App() {
    // Step < 5 représente les 5 tips
    // Step 5 est pour le tableau sur le peintre
    // Step 6 est pour le dernier indice
    const [step, setStep] = React.useState(localStorage.getItem(localStorageEnum.step) ? Number(localStorage.getItem(localStorageEnum.step)) : 0)
    const [displayPictures, setDisplayPictures] = React.useState(false)
    const [displaySeeMore, setDisplaySeeMore] = React.useState(false)

    useEffect(
        () => {
            if (window.innerHeight < document.body.clientHeight && !((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
                setDisplaySeeMore(true)
            } else {
                setDisplaySeeMore(false)
            }

            window.addEventListener('scroll', (ev) => {
                // Si on est pas en bas de la page et que la hauteur du device est inférieure à la hauteur de la page
                // (donc il y a plus de contenu que la taille du device)
                // On affiche l'icône
                if (window.innerHeight < document.body.clientHeight && !((window.innerHeight + window.scrollY) >= document.body.offsetHeight)) {
                    setDisplaySeeMore(true)
                } else {
                    setDisplaySeeMore(false)
                }
            })

            return () => window.removeEventListener('scroll', () => {})
        }, [step]
    )

    return (
        <Grid container item xs={12}>
            {/* Bloc avec l'input */}
            <Input step={step} setStep={setStep}/>

            {/* Bloc pour passer d'un onglet à l'autre */}
            <Grid container item xs={12} justifyContent="space-between">
                <Button onClick={() => setStep(0)} color="info">
                    Reset les steps
                </Button>

                <Button onClick={() => setDisplayPictures((prevState) => !prevState)} color="info">
                    {displayPictures ? 'Retourner aux indices' : 'Voir les photos'}
                </Button>
            </Grid>

            {
                displayPictures ? (
                    // Bloc pour les tableaux
                    <Pictures/>
                ) : (
                    // Bloc pour les tips
                    <Tips step={step}/>
                )
            }

            <InformativeModal />

            {
                displaySeeMore && (
                    <ExpandMoreIcon
                        sx={{
                            position: 'fixed',
                            right: 10,
                            bottom: 10,
                            color: 'white',
                            fontSize: 50
                        }}
                    />
                )
            }
        </Grid>
    )
}

export default App
