import {AlertColor} from '@mui/material'

export interface SnackBarModel {
    open: boolean,
    onSnackClosed: () => void
    message: string,
    color: AlertColor
}

export const INIT_SNACKBAR: SnackBarModel = {
    open: false,
    onSnackClosed: () => {},
    message: '',
    color: 'info'
}
