import React from 'react'
import {Grid} from '@mui/material'

const Pictures = () => {

    return (
        <Grid
            container
            item
            xs={12}
        >
            {
                Array.from([0, 1, 2, 3, 4]).map(
                    (index) => (
                        <img
                            src={`/paints/paint${index + 1}.jpg`}
                            alt={`Tableau ${index + 1}`}
                            width="100%"
                            key={`tableau-${index}`}
                            style={{
                                maxWidth: 650,
                                aspectRatio: '1535/2048',
                                margin: 'auto',
                                marginBottom: 20
                            }}
                        />
                    )
                )
            }
        </Grid>
    )
}

export default Pictures
